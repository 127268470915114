<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-08 15:14:23
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-08 10:32:06
 * @FilePath: \official_website\src\views\products\details\tctReagent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 液基细胞学TCT诊断试剂 -->
<template>
  <div id="tctReagent">
    <Header></Header>
    <div class="tctReagent-conter">
      <div class="tctReagent-img">
        <Breadcrumb
          :productType="productType"
          :productName="productName"
        ></Breadcrumb>
        <div class="illustrate-box">
          <div class="title wow animate__animated animate__fadeInUp">液基细胞学TCT诊断试剂</div>

          <div class="line"></div>
          <ul class="keywords wow animate__animated animate__fadeInUp">
            <li>灵活</li>
            <li>可靠</li>
            <li>高效</li>
            <li>可控</li>
            <li>稳定</li>
          </ul>
        </div>
        <div class="product-img">
          <!-- <img src="@/assets/image/product/film-1.png" alt="" /> -->
        </div>
      </div>
      <div class="tctReagent-description">
        <div class="parameter">
          <img class="wow animate__animated animate__fadeInUp" src="@/assets/image/tctReagent/parameter.png" alt="" />
        </div>
      </div>

      <RelatedProduct
        v-if="relatProd.length != 0"
        :swiperData="relatProd"
      ></RelatedProduct>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "@/views/componets/Header.vue";
import Footer from "@/views/componets/Footer.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
import Breadcrumb from "@/views/componets/Breadcrumb.vue";

export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    RelatedProduct,
    Breadcrumb,
  },
  data() {
    return {
      productType: "产品",
      productName: "液基细胞学TCT诊断试剂",
      relatProd: [],
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {},
};
</script>
    
<style lang="scss">
#tctReagent {
  width: 100%;
  height: 100%;
  .tctReagent-conter {
    padding-top: 5rem;
    .tctReagent-img {
      position: relative;
      height: 50rem;
      background-image: url("../../../assets/image/product-detail/product-bg.png");
      background-size: cover;
      // img {
      //  position: absolute;
      //  left: 45%;
      //  top: 5%;
      // }
      .illustrate-box {
        position: absolute;
        left: 18%;
        top: 30%;
        width: 21.875rem;
        .title {
          font-size: 1.8rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 2.8125rem;
        }
        .line {
          width: 4.25rem;
          height: 3px;
          margin-bottom: 1.25rem;
          background: #177a73;
        }
        .keywords {
          display: flex;
          li {
            width: 18.4%;
            height: 2.1875rem;
            line-height: 2.1875rem;
            margin-right: 2%;
            color: #fff;
            background: #177a73;
            border-radius: 2.1875rem;
            text-align: center;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
      .product-img {
        position: absolute;
        left: 45%;
        top: 12%;
        width: 50%;
        height: 75%;
        background-image: url("../../../assets/image/tctReagent/tctReagent.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .tctReagent-description {
      width: 100%;
      padding: 3.75rem 18%;
      .parameter {
        width: 100%;
        margin: 2rem 0 5rem;
        img {
          display: block;
          width: 90%;
          margin: auto;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>