<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-01 17:18:44
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-06 17:34:21
 * @FilePath: \official_website\src\views\products\componets\RelatedProduct.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 相关产品  -->
<template>
  <div id="relatedProduct">
    <div class="related-product" v-if="swiperData.length != 0">
      <div class="title">相关产品</div>
      <div class="line"></div>
      <div class="related-box">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide
            v-for="(item, index) in swiperData"
            :key="index"
            :data-href="item.summary"
          >
            <img :src="item.titleImage" alt="" />
            <div class="related-text">{{ item.title }}</div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>
  </div>
</template>
    
<script>
import "swiper/css/swiper.min.css";
import {
  Swiper,
  SwiperSlide,
} from "../../../../node_modules/vue-awesome-swiper/dist/vue-awesome-swiper";

export default {
  name: "",
  props: {
    swiperData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 52,
        // freeMode: true,
        // direction: "horizontal",
        slideToClickedSlide: true, //滑动到点击的slide
        // centeredSlides: true, //当前slide居中
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        // loop: true,
        navigation: {
          // 前进后退
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {},
  mounted() {
    let that = this;
    this.swiper.on("click", function () {
      // 这里的this是swiper的实例
      let item = this.clickedSlide.attributes["data-href"].nodeValue;
      that.tapPages(item);
    });

    if (that.swiperData.length < 4) {
      document.querySelector(".swiper-wrapper").style.justifyContent = "center";
    }
  },
  watch: {},
  computed: {
    // 获取swiper实例
    swiper() {
      return this.$refs.mySwiper ? this.$refs.mySwiper.$swiper : null;
    },
  },
  methods: {
    tapPages(path) {
      console.log(path);
      this.$router.push(path);
    },
  },
};
</script>
    
<style lang="scss">
#relatedProduct {
  .related-product {
    width: 100%;
    height: 37.5rem;
    padding: 2.5rem 8%;
    .title {
      font-size: 2.2rem;
      font-family: "SourceHanSansSC-Medium";
      text-align: center;
      margin-bottom: 1.25rem;
    }
    .line {
      width: 6.25rem;
      height: 0.375rem;
      margin: 0 auto 3.125rem;
      background: #0b847b;
    }
    .related-box {
      position: relative;
      height: 19.375;
      --swiper-navigation-color: #0b847b; /* 单独设置按钮颜色 */
      --swiper-navigation-size: 32px; /* 设置按钮大小 */
      .flex-center {
        justify-content: center;
      }
      .swiper-slide {
        height: 20rem;
        cursor: pointer;
        img {
          height: 17rem;
        }
        .related-text {
          padding-top: 0.625rem;
          text-align: center;
          color: #212121;
          font-family: "SourceHanSansSC-Medium";
        }
      }
    }
  }
}
</style>